import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, createBlock as _createBlock, createTextVNode as _createTextVNode } from "vue"

const _hoisted_1 = { class: "d-flex align-items-center justify-content-between" }
const _hoisted_2 = { class: "d-flex align-items-center" }
const _hoisted_3 = {
  key: 0,
  class: "ms-1"
}
const _hoisted_4 = { class: "d-flex align-items-center" }
const _hoisted_5 = ["onClick"]
const _hoisted_6 = ["onClick"]
const _hoisted_7 = { class: "ps-4 my-4" }
const _hoisted_8 = { class: "d-flex justify-content-center" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_localized_text = _resolveComponent("localized-text")!
  const _component_raw_material_list = _resolveComponent("raw-material-list")!
  const _component_edit_layer_transportation = _resolveComponent("edit-layer-transportation")!

  return (_openBlock(), _createElementBlock("div", null, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.list, (item, i) => {
      return (_openBlock(), _createElementBlock("div", {
        class: "mt-1",
        key: i
      }, [
        _createElementVNode("div", _hoisted_1, [
          _createElementVNode("h3", _hoisted_2, [
            _createVNode(_component_localized_text, {
              localizedKey: "Fabric {{layerName}}",
              text: "Fabric {{layerName}}",
              object: { layerName: item.layerName}
            }, null, 8, ["object"]),
            (i == 0)
              ? (_openBlock(), _createElementBlock("span", _hoisted_3, "(SQM: " + _toDisplayString(_ctx.$filters.approximation(item.sqm)) + ")", 1))
              : _createCommentVNode("", true)
          ]),
          _createElementVNode("div", _hoisted_4, [
            _createElementVNode("button", {
              class: "btn btn-secondary me-2",
              onClick: ($event: any) => (_ctx.editLayer(item, i))
            }, [
              _createVNode(_component_localized_text, {
                localizedKey: "Edit",
                text: "Edit"
              })
            ], 8, _hoisted_5),
            _createElementVNode("button", {
              class: "btn btn-primary",
              onClick: ($event: any) => (_ctx.removeLayer(item.layerName))
            }, [
              _createVNode(_component_localized_text, {
                localizedKey: "Remove",
                text: "Remove"
              })
            ], 8, _hoisted_6)
          ])
        ]),
        _createElementVNode("div", _hoisted_7, [
          _createVNode(_component_raw_material_list, { item: item }, null, 8, ["item"])
        ]),
        (_openBlock(), _createBlock(_component_edit_layer_transportation, {
          estimationIdentifier: _ctx.estimationIdentifier,
          countries: _ctx.electricityCountries,
          transportDistanceCalculation: _ctx.transportationCountries,
          layer: item,
          currentTransportation: _ctx.rawMaterialTransportPhases[item.layerName],
          productionPhase: 'Raw Material',
          onInit: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('init'))),
          saveCallback: _ctx.saveTransportationCallback,
          removeCallback: _ctx.removeCallback,
          key: item.layerName + 'Raw Material',
          rawMaterialCallToAction: true
        }, null, 8, ["estimationIdentifier", "countries", "transportDistanceCalculation", "layer", "currentTransportation", "saveCallback", "removeCallback"]))
      ]))
    }), 128)),
    _createElementVNode("div", _hoisted_8, [
      _createElementVNode("button", {
        class: "btn btn-tertiary",
        onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.editLayer(null, null)))
      }, [
        _createTextVNode(" + "),
        _createVNode(_component_localized_text, {
          localizedKey: "Add Fabric",
          text: "Add Fabric"
        })
      ])
    ])
  ]))
}